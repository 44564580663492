import React, { useState, useEffect } from 'react';

interface AgeCounterProps {
  render: (age: string) => React.ReactNode;
}

const AgeCounter: React.FC<AgeCounterProps> = ({ render }) => {
  const [age, setAge] = useState<number>(calculateAge());

  function calculateAge(): number {
    const birthDate = new Date('2001-08-10T00:00:00-05:00');
    const now = new Date();
    const diffInMilliseconds = now.getTime() - birthDate.getTime();
    const ageInYears = diffInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);
    return ageInYears;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setAge(calculateAge());
    }, 50); // Update more frequently for smoother animation

    return () => clearInterval(timer);
  }, []);

  return <>{render(age.toFixed(7))}</>;
};

export default AgeCounter;